import React, { useContext, useState, useEffect } from "react";
import "./Blog.scss";
import BlogCard from "../../components/blogCard/BlogCard";
import { blogSection, socialMediaLinks } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import axios from "axios";
export default function Blogs() {
  const { isDark } = useContext(StyleContext);
  let [articles, setArticles] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  if (!blogSection.display) {
    return null;
  }
  const getData = async () => {
    let data = await axios.get(
      "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@ujjwolkayastha"
    );

    let requiredData = [];
    data.data.items.map((data, index) => {
      if (index < 3) {
        requiredData.push(data);
      }
      return data;
    });
    setArticles(requiredData);
  };

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="blogs">
        <div className="blog-header">
          <h1 className="blog-header-text">Blogs</h1>
          <p
            className={
              isDark ? "dark-mode blog-subtitle" : "subTitle blog-subtitle"
            }
          >
            With Love for Developing cool stuff, I love to write and teach
            others what I have learnt.
          </p>
        </div>
        <div className="blog-main-div">
          <div className="blog-text-div">
            {articles.map((blog, i) => {
              return (
                <BlogCard
                  key={blog.guid}
                  isDark={isDark}
                  blog={{
                    url: blog.link,
                    image: blog.thumbnail,
                    title: blog.title,
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="read-more">
          <i className="fab fa-medium"></i>
          <a href={socialMediaLinks.medium} target="_blank" rel="noreferrer">
            Read More <i class="fa fa-arrow-right" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </Fade>
  );
}
