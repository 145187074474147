import React, { useContext, useEffect } from "react";
import "./Progress.scss";
import { techStack, tagCloudData } from "../../portfolio";
import { Fade } from "react-reveal";
import TagCloud from "TagCloud";
import { useWindowDimensions } from "../../hooks/useWindowsDimension";
import StyleContext from "../../contexts/StyleContext";

export default function StackProgress() {
  const dimensions = useWindowDimensions();
  let radius;

  if (dimensions.width <= 400) {
    radius = 100;
  } else if (dimensions.width > 400 && dimensions.width < 768) {
    radius = 200;
  } else {
    radius = 350;
  }

  const { isDark } = useContext(StyleContext);

  useEffect(() => {
    const tag = TagCloud(".tag-cloud", tagCloudData, {
      radius: radius,
      maxSpeed: "normal",
      initSpeed: "normal",
      keep: true,
      direction: 135,
      containerClass: "tagcloud-container",
    });
    return () => tag.destroy();
  }, [radius]);

  const profText = (
    <p>
      Started working as Teaching Assistant during final year of the University.
      Then worked as Faculty Research Assistant creating and learning pervasive
      computing devices and drones assembly. Being an active learner I can learn
      new technologies in minimal amount of time along with good communication
      and problem solving skills. A team player.
      <blockquote className={isDark ? "quote-dark EN" : "quote EN"}>
        "Challenges are opportunities that knocks on the door."
      </blockquote>
      Besides my work, I sing, travel, enjoy any form of art that touches the
      soul. I’m in a continuous learning process.
    </p>
  );

  if (techStack.viewSkillBars) {
    return (
      <Fade bottom duration={1000} distance="20px">
        <div className="skills-container">
          <div className="skills-bar">
            <h1 className="skills-heading">Proficiencies</h1>
            <div className="prof-container">
              <span className={isDark ? "prof-text-dark" : "prof-text"}>
                {profText}
              </span>
              <span className="tag-cloud"></span>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
  return null;
}
