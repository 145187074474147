/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

// Summary And Greeting Section

import StXaviers from './assets/images/stxavierslogo.jpg';
import TU from './assets/images/tu.png';
import Lincoln from './assets/images/lincolnuni.png';
import Coventry from './assets/images/coventry.png';
import Wesionary from './assets/images/wesionary.png';

const illustration = {
  animated: true, // set to false to use static SVG
};

const greeting = {
  username: 'Ujjwol Kayastha',
  title: "नमस्कार 🙏, I'm Ujjwol",
  subTitle:
    'A person in his own journey to living and learning. An enthusiastic dreamer who believes that dreams come true if we perceive it with endurance and hard-work.',
  resumeLink:
    'https://public.tableau.com/app/profile/.ujjwol.kayastha/viz/CurriculumVitae_15789019612790/Dashboard1',
  displayGreeting: true, // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: 'https://github.com/UjjwolKayastha',
  linkedin: 'https://www.linkedin.com/in/ujjwolkayastha/',
  gmail: 'ujjwolkayastha@gmail.com',
  facebook: 'https://www.facebook.com/kayasthaujjwol',
  medium: 'https://ujjwolkayastha.medium.com/',
  instagram: 'https://www.instagram.com/ujjwolbaabu/',
  twitter: 'https://twitter.com/ujjwolkayastha',
  youtube: 'https://www.youtube.com/c/UjjwolKayastha',
  // Instagram and Twitter are also supported in the links!
  display: true, // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: 'What I do',
  subTitle:
    'ENTHUSIASTIC FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK',
  skills: [
    '⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications',
    '⚡ Integration of third party services such as Firebase/ AWS / GCP',
    '🖋️ I regularly write medium articles to share what I learned.',
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: 'html-5',
      fontAwesomeClassname: 'fab fa-html5',
    },
    {
      skillName: 'css3',
      fontAwesomeClassname: 'fab fa-css3-alt',
    },
    {
      skillName: 'sass',
      fontAwesomeClassname: 'fab fa-sass',
    },
    {
      skillName: 'JavaScript',
      fontAwesomeClassname: 'fab fa-js',
    },
    {
      skillName: 'reactjs',
      fontAwesomeClassname: 'fab fa-react',
    },
    {
      skillName: 'nodejs',
      fontAwesomeClassname: 'fab fa-node',
    },
    {
      skillName: 'android',
      fontAwesomeClassname: 'fab fa-android',
    },
    {
      skillName: 'npm',
      fontAwesomeClassname: 'fab fa-npm',
    },
    {
      skillName: 'sql-database',
      fontAwesomeClassname: 'fas fa-database',
    },
    {
      skillName: 'aws',
      fontAwesomeClassname: 'fab fa-aws',
    },
    {
      skillName: 'firebase',
      fontAwesomeClassname: 'fas fa-fire',
    },
    {
      skillName: 'java',
      fontAwesomeClassname: 'fab fa-java',
    },
    {
      skillName: 'docker',
      fontAwesomeClassname: 'fab fa-docker',
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};

const tagCloudData = [
  'JavaScript',
  'CSS',
  'HTML',
  'ReactJS',
  'NextJS',
  'NodeJS',
  'Git',
  'MySQL',
  'Typescript',
  'Docker',
  'SASS',
  'Postgres',
  'REST',
  'Firebase',
  'MongoDB',
  'Android',
  'IOT',
  'Golang',
  'React Native',
  'Flutter',
  'GraphQL',
];

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: 'Lincoln University',
      logo: Lincoln,
      subHeader: 'Masters in Computer Science',
      duration: '2022 - Present',
    },
    {
      schoolName: 'Coventry University',
      logo: Coventry,
      subHeader: 'Bsc. (Hons) in Computing',
      duration: '2017 - 2020',
      desc: 'Ranked top 1% in the program. Took courses about Software Engineering, Web Security, Operating Systems, Android and Web development, IOT',
    },
    {
      schoolName: 'Tribhuwan University',
      logo: TU,
      subHeader: 'Bachelor in Business Studies',
      duration: '2014 - 2018',
    },
    {
      schoolName: "St. Xavier's College",
      logo: StXaviers,
      subHeader: '+2 Science',
      duration: '2010 - 2012',
    },
  ],
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: 'Frontend/Design', //Insert stack or technology you have experience in
      progressPercentage: '90%', //Insert relative proficiency in percentage
    },
    {
      Stack: 'Backend',
      progressPercentage: '70%',
    },
    {
      Stack: 'Programming',
      progressPercentage: '60%',
    },
  ],
  displayCodersrank: false, // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: 'Teaching Assistant',
      company: 'Softwarica College of IT & E-commerce',
      companylogo: Coventry,
      date: 'June 2019 – August 2019',
      desc: 'Engaged in academic support and lab tutor.',
      descBullets: ['Android', 'NodeJS', 'IOT'],
      descBulletsTitle: 'Engaged in courses:',
    },
    {
      role: 'Faculty Research Assistant',
      company: 'Softwarica College of IT & E-commerce',
      companylogo: Coventry,
      date: 'September 2019 – June 2020',
      desc: 'Engaged as lab tutor and Research assistant',
      descBullets: ['NodeJS', 'Python', 'IOT', 'Drone assembly'],
      descBulletsTitle: 'Involved in courses:',
    },
    {
      role: 'Software Engineer / Team Manager',
      company: 'WesionaryTEAM',
      companylogo: Wesionary,
      date: 'June 2020 – Present',
      desc: 'Working as full-stack software developer and team manager.',
      descBulletsTitle: '',
    },
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: 'true', // Set true or false to show Contact profile using Github, defaults to true
  display: true, // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: '',
  subtitle: '',
  projects: [
    {
      image: StXaviers,
      projectName: '',
      projectDesc: '',
      footerLink: [
        {
          name: '',
          url: '',
        },
      ],
    },
  ],
  display: false, // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: 'Achievements And Certifications 🏆 ',
  subtitle:
    'Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !',

  achievementsCards: [
    {
      title: '',
      subtitle: '',
      image: '',
      footerLink: [
        {
          name: '',
          url: '',
        },
        {
          name: '',
          url: '',
        },
      ],
    },
  ],
  display: false, // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: 'Blogs',
  subtitle:
    'With Love for Developing cool stuff, I love to write and teach others what I have learned.',

  blogs: [
    {
      url: '',
      title: '',
      description: '',
    },
  ],
  display: true, // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: 'TALKS',
  subtitle: 'I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅',
  talks: [
    {
      title: '',
      subtitle: '',
      slides_url: '',
      event_url: '',
    },
  ],
  // display: true,
};

// Podcast Section

const podcastSection = {
  title: 'Music 🎼 🎶',
  subtitle: 'I LOVE TO SING 🎙️ AND PLAY GUITAR. 🎸',
  soundcloudurl: 'https://soundcloud.com/ahtsayak',

  // Please Provide with Your Podcast embeded Link
  podcast: [
    // "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1117684948",
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1131912184',
    'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1131912805',
  ],
  display: true, // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: 'Contact Me ☎️',
  subtitle:
    'Discuss a project or just want to say hi? My Inbox is open for all.',
  number: '+977-9849577220',
  email_address: 'ujjwolkayastha@gmail.com',
};

// Twitter Section

const twitterDetails = {
  userName: 'ujjwolkayastha', //Replace "twitter" with your twitter username without @
  display: true, // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  tagCloudData,
};
