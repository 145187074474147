import React, { useContext } from "react";
import "./Podcast.scss";
import { podcastSection } from "../../portfolio";
import { Fade } from "react-reveal";
import StyleContext from "../../contexts/StyleContext";

export default function Podcast() {
  const { isDark } = useContext(StyleContext);
  if (!podcastSection.display) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main">
        <div className="podcast-header">
          <h1 className="podcast-header-title">{podcastSection.title}</h1>
          <p
            className={
              isDark
                ? "dark-mode podcast-header-subtitle"
                : "subTitle podcast-header-subtitle"
            }
          >
            {podcastSection.subtitle}
          </p>
        </div>
        <div className="podcasts-wrapper">
          <div className="podcast-main-div">
            {podcastSection.podcast.map((podcastLink, i) => {
              return (
                <div key={i}>
                  <iframe
                    className="podcast"
                    src={podcastLink}
                    frameBorder="0"
                    scrolling="no"
                    title="Podcast"
                  ></iframe>
                </div>
              );
            })}
          </div>
          <div className="read-more">
            <i className="fab fa-soundcloud"></i>
            <a
              href={podcastSection.soundcloudurl}
              target="_blank"
              rel="noreferrer"
            >
              Listen More <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </Fade>
  );
}
